/**
 * Module made global by using Webpack ProvidePlugin
 * You can then safely use trans.t(...) anywhere on compiled JSX files without the need to import anything
 */

import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';


let language = window['LANGUAGE'] || 'fr';
let translationFile = window['TRANSLATION_FILE_PATH'] || '';

if(language == 'fr-FR'){
    language = 'fr';
}
i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: language,
        interpolation: {
            escapeValue: false
        },
        keySeparator: false,
        nsSeparator: false,
        fallbackLng: 'fr',
        backend: {
            loadPath: translationFile
        },
        returnEmptyString: false,
    });

export { i18n };

export function t(...args){
    return i18n.t(...args);
}